import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LoadingController } from "@ionic/angular";
import { GlobalServiceService } from "./global-service.service";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class ApiService {
	constructor(
		private http: HttpClient,
		private global: GlobalServiceService,
		public loadingController: LoadingController
	) {}

	httpOptions = {
		headers: new HttpHeaders({}),
	};

	get_table(sessiontoken, table, parameter): Observable<any> {
		this.httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				Authorization: "Bearer " + sessiontoken,
			}),
		};

		const requestOptions = new HttpHeaders({
			"Content-Type": "application/json",
		});
		return this.http.get(
			this.global.apiurl + "/api/" + `${table}${parameter}`,
			this.httpOptions
		);
	}

	post_table(sessiontoken, table, postData) {
		this.httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				Authorization: "Bearer " + sessiontoken,
			}),
		};
		const requestOptions = new HttpHeaders({
			"Content-Type": "application/json",
		});
		return this.http.post(
			this.global.apiurl + "/api/" + `${table}`,
			postData,
			this.httpOptions
		);
	}

	put_table(sessiontoken, table, postData) {
		this.httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				Authorization: "Bearer " + sessiontoken,
			}),
		};
		return this.http.put(
			this.global.apiurl + "/api/" + `${table}`,
			postData,
			this.httpOptions
		);
	}

	delete_table(sessiontoken, table, id) {
		this.httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				Authorization: "Bearer " + sessiontoken,
			}),
		};
		return this.http.delete(
			this.global.apiurl + "/api/" + `${table}` + id,
			this.httpOptions
		);
	}

	currentLoading = null;

	async present(message: string = null, duration: number = null) {
		// Dismiss previously created loading
		if (this.currentLoading != null) {
			this.currentLoading.dismiss();
		}

		this.currentLoading = await this.loadingController.create({
			duration: duration,
			message: message,
		});

		return await this.currentLoading.present();
	}

	async dismiss() {
		if (this.currentLoading != null) {
			await this.loadingController.dismiss();
			this.currentLoading = null;
		}
		return;
	}
}
