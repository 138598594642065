import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import {GlobalServiceService} from "./global-service.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate{
    constructor(private router: Router, private auth: AuthService, private alertCtrl: AlertController,private global: GlobalServiceService) { }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const expectedRoles = route.data.roles;
        return this.auth.user.pipe(
            take(1),
            map(user => {
                if (!user) {
                    this.alertCtrl.create({
                        header: 'Unauthorized',
                        message: 'You are not allowed to access that page.',
                        buttons: ['OK']
                    }).then(alert => alert.present());

                    this.router.navigateByUrl('/');
                    return false;
                } else {
                    if (expectedRoles.length > 0) {
                        const role = [];
                        if (this.global.RollenOID == 3 ) {role.push('Admin');}
                        if (this.global.RollenOID == 4) {
                            role.push('Systemadmin');
                            role.push('Admin');
                        }
                        if (expectedRoles.some(r => role.includes(r))) {
                            return true;
                        } else {
                            this.alertCtrl.create({
                                header: 'Unauthorized',
                                message: 'Sie sind nicht Berechtigt diese Seite aufzurufen',
                                buttons: ['OK']
                            }).then(alert => alert.present());
                            this.router.navigateByUrl('/');
                            return false;
                        }
                    } else {
                        return true;
                    }
                }
            })
        );
    }
}
