import { Injectable } from "@angular/core";
import {
	Veranstaltung,
	VeranstaltungOrte,
} from "../pages/einstellungen/einstellungen.page";

@Injectable({
	providedIn: "root",
})
export class GlobalServiceService {
	public apiurl = "https://sea-api.virtuelle-leitwarte.de";
	public apkikey =
		"e0c4a4fc01e5a51792ed718ba5a965f82e10a94deb9cbc5e63359d33effe069f";
	public appname: "smartaccess";
	public username: string;
	public password: string;
	public BenutzerOID: number;
	public OranisationOID: number;
	public RollenOID: number;
	public AutomatischerEingang: boolean;
	public ErfassungEintrittskarte: boolean;
	public VeranstaltungOID: number;
	public VeranstaltungsOrt: number;
	public Benutzer: Benutzer;
	public Platform;
	constructor() {}
}
export class Benutzer {
	Oid: number;
	Anmeldename: number;
	Vorname: string;
	Nachname: string;
	VeranstaltungOID: Veranstaltung;
	VeranstaltungsOrtOID: VeranstaltungOrte;
	Funktion: string;
	Benutzer_by_BenutzerOID: Benutzer;
	ErfassungEintrittskarte: boolean;
	AutomatischerEingang: boolean;
	Startmaske: string;
	GeoLocation: boolean;
	Geolocation_Browser: string;
	Refreshtimer_Erfassung: number;
	Refreshtimer_Scan: number;
	Vibration: boolean;
	TastaturHide: boolean;
	RollenOID: Rolle;
	VeranstaltungsOrtwaehlen: boolean;
}

export class Rolle {
	Oid: number;
	Name: string;
}
