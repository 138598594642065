import { Platform } from "@ionic/angular";
import { Injectable } from "@angular/core";
import { BehaviorSubject, from, Observable, of } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GlobalServiceService } from "./global-service.service";
import { ApiService } from "./api.service";
import { Plugins } from "@capacitor/core";

const { Storage } = Plugins;
const helper = new JwtHelperService();
const TOKEN_KEY = "jwt-token";

@Injectable({
	providedIn: "root",
})
export class AuthService {
	public information;
	public user: Observable<any>;
	private userData = new BehaviorSubject(null);
	// tslint:disable-next-line:max-line-length
	constructor(
		private http: HttpClient,
		private plt: Platform,
		private router: Router,
		private global: GlobalServiceService,
		private api: ApiService
	) {
		this.loadStoredToken();
	}

	loadStoredToken() {
		const platformObs = from(this.plt.ready());

		this.user = platformObs.pipe(
			switchMap(() => {
				return from(this.getObject(TOKEN_KEY));
			}),
			map((token) => {
				if (token && !helper.isTokenExpired(token)) {
					const decoded = helper.decodeToken(token);
					this.userData.next(decoded);
					return true;
				} else {
					return null;
				}
			})
		);
	}

	login(credentials: { email: string; pw: string }) {
		const headers = new Headers();
		headers.append("Accept", "application/json");
		headers.append("Content-Type", "application/json");
		const requestOptions = new HttpHeaders({
			"Content-Type": "application/json",
		});
		const postData = {
			Email: credentials.email,
			Passwort: credentials.pw,
		};
		return this.http
			.post(this.global.apiurl + "/api/Auth/Login", postData)
			.pipe(
				take(1),
				map(
					(res) => {
						this.information = res;
						// Extract the JWT, here we just fake it
						return res["Token"];
					},
					(error) => {
						console.log(error);
						return of(null);
					}
				),
				map((token) => {
					let decoded = helper.decodeToken(token);
					this.userData.next(decoded);
					let storageObs = from(
						Storage.set({
							key: TOKEN_KEY,
							value: JSON.stringify(token),
						})
					);
					return JSON.stringify(token);
				})
			);
	}

	getUser() {
		return this.userData.getValue();
	}

	logout() {
		this.removeItem(TOKEN_KEY).then(() => {
			this.router.navigateByUrl("/");
			this.userData.next(null);
		});
	}

	async setItem(name, key) {
		await Storage.set({ key: name, value: key });
	}

	async removeItem(name) {
		await Storage.remove({ key: name });
	}

	async getObject(name) {
		const ret = await Storage.get({ key: name });
		return JSON.parse(ret.value);
	}
}
